/* inject:scss */
/* endinject */
.nav-link {
  display: flex !important;
  align-items: center; }

.sidebar {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 1;
  top: 45px;
  left: 0;
  background-color: #2D3033;
  padding-top: 16px; }
  .sidebar a {
    padding: 10px 8px 10px 16px;
    text-decoration: none;
    display: block;
    border-left: 4px solid transparent;
    color: #fff; }
    .sidebar a:hover {
      color: #0EBFBE; }
    .sidebar a.active {
      color: #0EBFBE;
      border-left: 4px solid #0EBFBE;
      background-color: #25282b; }
