.draft {
  color: #FF920F; }

.react-bootstrap-table-pagination {
  display: none; }

.Draft {
  color: #FF920F; }

.Live {
  color: #8f9298; }
