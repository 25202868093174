.remove-icon {
  margin: 10px 30px 0;
  color: #E21C10;
  cursor: pointer; }

.btn-primary {
  background-color: #0091ff !important;
  border-color: #0091ff !important;
  color: #fff;
  margin: 5px 15px 0; }

.btn-danger {
  border-color: #E21C10 !important;
  margin: 0px 15px 0; }

.mt-5 {
  margin-top: 5px; }

.play-btn {
  outline: none;
  width: 70px;
  color: #fff !important; }
  .play-btn:focus {
    outline: none; }

.type-label {
  margin-top: 10px; }

.modal-upload-btn input {
  height: 0;
  overflow: hidden;
  width: 0; }

.modal-upload-btn label {
  background: #5cb85c;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 600;
  outline: none;
  padding: 10px 25px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  width: 100%; }
