.mr-5 {
  margin-right: 30px; }

.btn-green {
  background-color: #0FAA47 !important; }

.panel-heading {
  padding: 5px 15px;
  background-color: #fff !important;
  display: flex;
  justify-content: space-between; }

.stages-table {
  float: left; }
  .stages-table .customTableContainer tbody tr {
    cursor: pointer; }

.panel .customTableContainer .table-header {
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
  color: #8f9298; }

.panel .customTableContainer tbody tr:nth-child(even) {
  background-color: #fff; }

.panel .customTableContainer .table-bordered {
  border: none;
  margin: 0; }

.panel .customTableContainer .table > tbody > tr > td, .panel .customTableContainer .table > tbody > tr > th, .panel .customTableContainer .table > tfoot > tr > td,
.panel .customTableContainer .table > tfoot > tr > th, .panel .customTableContainer .table > thead > tr > td, .panel .customTableContainer .table > thead > tr > th {
  padding: 8px 15px; }

.panel .customTableContainer .table > thead > tr > th {
  font-weight: 600; }

td {
  text-align: left;
  border: none !important; }

th {
  text-align: left; }

.action {
  margin-right: 1rem; }

.center {
  align-content: center;
  margin-left: 5px; }

.table-bordered > thead > tr > td {
  border-bottom-width: 0px !important;
  border: none !important; }

.table-bordered > thead > tr > th {
  border: none !important; }

.table-bordered > thead > tr {
  border-top: 1px solid #ddd; }

.table-bordered > tbody {
  border: none !important; }
  .table-bordered > tbody tr:nth-child(odd) {
    background-color: #F9FAFF; }

.edit-icon {
  color: #FF920F;
  margin-right: 10px;
  cursor: pointer; }

.delete-icon {
  color: #E21C10;
  cursor: pointer; }

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }
  .btn:disabled {
    background-color: #fff;
    border-color: #ddd;
    color: #333; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }

.create-btn {
  margin-top: 3px; }
  .create-btn button {
    color: #fff;
    font-size: 14px;
    outline: none; }
    .create-btn button:hover {
      color: #fff; }

.publish {
  width: 80px;
  color: #00AC42;
  border: 1px solid #00AC42; }

.btn-width {
  width: 80px; }

.unpublish {
  width: 80px;
  color: #8b97a0;
  border: 1px solid #8b97a0; }

.react-bootstrap-table-pagination {
  display: none; }

.live {
  color: #8f9298; }

.workout-table {
  background: #fff;
  border-top: none; }
  .workout-table h5 {
    font-size: 12px;
    font-weight: 600;
    color: #8f9298;
    padding: 0 15px; }
  .workout-table .table-header {
    border-bottom: 1px solid #ddd; }
  .workout-table .table-content p {
    padding: 10px 0 10px 15px;
    margin: 0;
    cursor: pointer;
    color: #000; }
  .workout-table .table-content p.cnt-value {
    padding-left: 0; }
  .workout-table .table-content .table-row:nth-child(even) {
    background: #F9FAFF; }
  .workout-table .table-content .table-row:nth-child(odd) {
    background: #fff; }
  .workout-table .table-content .table-row.active {
    background-color: #d5edff !important; }

.add-icon {
  font-size: 12px; }

.workout-pagination {
  margin-right: 30px; }

@media (max-width: 991px) {
  .panel {
    width: 100%;
    margin: 0 0 20px;
    float: left; } }
