.disable-div {
  display: none; }

.enable-div {
  display: block; }

.verify-sec {
  margin: 20px 0 0; }

.submit-sec {
  text-align: right;
  margin: 20px 0 0; }
  .submit-sec .btn {
    margin-right: 10px;
    background-color: #0362f6;
    color: #fff;
    border-radius: 0;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 4px; }
  .submit-sec .resend-btn {
    background-color: #0362f6;
    margin-top: 0;
    border: none; }
    .submit-sec .resend-btn:hover, .submit-sec .resend-btn:focus {
      background-color: #0362f6;
      outline: none; }
  .submit-sec .submit-btn {
    background-color: #00AC42;
    margin: 0; }
    .submit-sec .submit-btn:hover, .submit-sec .submit-btn:focus {
      background-color: #00AC42;
      outline: none; }
