.ws-edit-icon {
  color: #FF920F;
  margin-left: 20px;
  cursor: pointer; }

.live {
  color: #8f9298 !important; }

.draft {
  color: #FF920F !important; }
