$appBackgroundColor: #faf7f8;


$contrastTextColour: #fff;
$contrastBgColour: #fff;
$primaryColor-130: #101013;
$primaryColor-120: #0362f6;
$primaryColor: #2D3033;
$primaryColor-dark: #161616;
$bright-blue: #0091ff;
$primaryTextColor: #777;
$secondaryTextColor: #000;
$workoutTextColor: #1a173b;
$workoutInactiveColor: #8b97a0;

$box-shadow: 0 8px 18px rgba(199, 199, 199, 0.2);

$border-color: #ddd;
$border-color-10: #efefef;
$hower-color: #0EBFBE;
$table-hdr-color: #8f9298;
$button-green: #00AC42;
$light-grey: #CECECE;
$light-blue: #3061CB;
$primary-red: #E21C10;
$primary-orange: #FF920F;
$primary-green: #0FAA47;

//Colors
$white: #fff;
