.p-r-5 {
  padding-right: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-0 {
  margin: 0 !important; }

.m-t-b-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.search-box {
  position: relative;
  padding-top: 0px; }
  .search-box input {
    border-radius: 0;
    border: 0;
    background-color: transparent;
    border-bottom: 2px solid #CECECE;
    min-height: 40px;
    padding-left: 25px;
    text-overflow: ellipsis; }
    .search-box input:focus {
      outline: none;
      border-radius: 0;
      border-bottom: 2px solid #CECECE;
      transition: 0.2s ease all; }
  .search-box input[type=text]::-ms-clear {
    display: none; }
  .search-box .icon-search {
    position: absolute;
    padding: 8px 10px 10px 0;
    pointer-events: none;
    left: 0px;
    right: 0px; }
    .search-box .icon-search i {
      color: #CECECE; }

.frames-heading {
  padding: 5px 15px;
  background-color: #fff !important;
  display: block !important; }

.upload-btn {
  margin-left: 15px;
  margin-top: 3px; }
  .upload-btn button {
    color: #fff;
    font-size: 14px;
    outline: none; }
    .upload-btn button:hover {
      color: #fff; }

.sort-by {
  margin-right: 15px;
  margin-top: 3px; }

.list-view i {
  border: 1px solid #CECECE;
  border-radius: 5px;
  margin-top: 7px;
  padding: 5px;
  cursor: pointer; }

.list-view .active {
  color: #3061CB;
  border-color: #3061CB; }

.thumbnail .img-border {
  text-align: center;
  border: 1px solid #eee; }

.thumbnail i {
  color: #E21C10;
  cursor: pointer; }

.thumbnail .file-size {
  font-weight: 100;
  float: left; }

.thumbnail .caption span {
  text-overflow: ellipsis; }

.thumbnail .caption .logo-file-name {
  width: 105px; }
