.login-container {
  background: linear-gradient(0deg, #AD4B0F, #AB1300);
  width: 100%;
  height: 100vh;
  display: flex; }
  .login-container .shadow-container {
    width: 30%;
    margin: 100px 35%;
    display: table;
    height: calc(100vh - 220px);
    border-radius: 15px; }
    .login-container .shadow-container .login-logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 33%;
      border-radius: 20px; }
  .login-container .content {
    display: table-cell;
    vertical-align: middle; }
    .login-container .content .card {
      background-color: #161616; }
    .login-container .content .card-signin {
      width: 350px; }
      .login-container .content .card-signin .card-title {
        margin-bottom: 2rem;
        font-size: 4rem;
        font-weight: 700;
        color: #fff; }
      .login-container .content .card-signin .card-body {
        padding: 2rem; }
        .login-container .content .card-signin .card-body h3 {
          color: #fff;
          text-align: center;
          margin: 20px 0;
          font-weight: 600; }
        .login-container .content .card-signin .card-body p {
          color: #fff; }
      .login-container .content .card-signin .form-signin .form-label-group {
        margin-bottom: 25px; }
      .login-container .content .card-signin .form-signin .d-flex {
        display: flex;
        justify-content: space-between; }
        .login-container .content .card-signin .form-signin .d-flex label {
          margin-top: 6px; }
      .login-container .content .card-signin .form-signin .btn {
        font-size: 1.3rem;
        opacity: 1;
        letter-spacing: .1rem;
        font-weight: 600;
        padding: 1rem 3rem;
        float: right;
        border-color: #00AC42;
        color: #fff !important;
        background-color: #00AC42; }
        .login-container .content .card-signin .form-signin .btn:hover {
          background-color: #00AC42; }
      .login-container .content .card-signin .form-signin .error-text {
        position: absolute;
        font-size: 13px; }
      .login-container .content .card-signin .form-signin .forgot-password {
        color: #fff;
        font-weight: 600; }
        .login-container .content .card-signin .form-signin .forgot-password:hover, .login-container .content .card-signin .form-signin .forgot-password:focus {
          color: #fff;
          text-decoration: none; }
