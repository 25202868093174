/* inject:scss */
/* endinject */
/* inject:scss */
/* endinject */
body {
  color: #777;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; }

.btn-primary {
  border-color: #2D3033;
  background-color: #fff;
  color: #2D3033; }
  .btn-primary:hover {
    background-color: #2D3033; }

label {
  font-weight: normal; }

.navbar-default {
  border-color: transparent; }
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:focus,
  .navbar-default .navbar-nav > .open > a:hover {
    color: #fff;
    background-color: #0362f6; }

.navbar-container .navbar .dropdown-toggle {
  color: #2D3033; }

.dropdown-menu li a {
  color: #777; }

.font-medium {
  font-weight: 600; }

.font-bold {
  font-weight: 700; }

.remv-padd {
  padding: 0 !important; }

.btn:disabled {
  cursor: not-allowed !important;
  color: #ddd !important;
  border-color: #ddd !important; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.error-text {
  color: red; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px !important; }

/* inject:scss */
/* endinject */
.appContainer {
  font-size: 14px; }
  .appContainer .p-0 {
    padding: 0px !important; }
  .appContainer .pt-10 {
    padding-top: 10px !important; }
  .appContainer .pb-10 {
    padding-bottom: 10px !important; }
  .appContainer .m-0 {
    margin: 0px !important; }
  .appContainer .mt-10 {
    margin-top: 10px; }
  .appContainer .mt-20 {
    margin-top: 20px; }
  .appContainer .mb-10 {
    margin-bottom: 10px; }
  .appContainer .mb-20 {
    margin-bottom: 20px; }
  .appContainer .mb-0 {
    margin-bottom: 0px; }
  .appContainer .cursor-pointer {
    cursor: pointer; }
  .appContainer .shadow-container {
    box-shadow: 0 8px 18px rgba(199, 199, 199, 0.2);
    border-radius: 25px;
    border: 1px solid #efefef;
    background-color: #161616;
    padding: 20px;
    margin-bottom: 20px; }
  .appContainer .full-height {
    min-height: calc(100vh - 90px); }
