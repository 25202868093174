.p-r-5 {
  padding-right: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-b-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.search-box {
  position: relative;
  padding-top: 0px; }
  .search-box input {
    border-radius: 0;
    border: 0;
    background-color: transparent;
    border-bottom: 2px solid #CECECE;
    min-height: 40px;
    padding-left: 25px;
    text-overflow: ellipsis; }
    .search-box input:focus {
      outline: none;
      border-radius: 0;
      border-bottom: 2px solid #CECECE;
      transition: 0.2s ease all; }
  .search-box input[type=text]::-ms-clear {
    display: none; }
  .search-box .icon-search {
    position: absolute;
    padding: 8px 10px 10px 0;
    pointer-events: none;
    left: 0px;
    right: 0px; }
    .search-box .icon-search i {
      color: #CECECE; }

.frames-heading {
  padding: 5px 15px;
  background-color: #fff !important;
  display: block !important; }

.upload-btn {
  cursor: pointer;
  margin-left: 15px;
  margin-top: 3px;
  position: relative; }
  .upload-btn button {
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    outline: none; }
    .upload-btn button:hover {
      color: #fff; }
  .upload-btn input::-webkit-file-upload-button {
    visibility: hidden; }
  .upload-btn input {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }

.sort-by {
  margin-right: 15px;
  margin-top: 3px; }

.sort-by-label {
  margin: 10px 10px 0px 0px; }

.list-view i {
  border: 1px solid #CECECE;
  border-radius: 5px;
  margin-top: 7px;
  padding: 5px;
  cursor: pointer; }

.list-view .active {
  color: #3061CB;
  border-color: #3061CB; }

.hidden {
  display: none; }

.thumbnail .img-border {
  text-align: center;
  border: 1px solid #eee; }
  .thumbnail .img-border img {
    cursor: pointer; }

.thumbnail i {
  color: #E21C10;
  cursor: pointer; }

.thumbnail .file-size {
  font-weight: 100;
  float: left; }

.file-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  display: inline-block; }

.frames-pagination {
  float: right;
  margin-right: 15px; }
  .frames-pagination li {
    cursor: pointer; }
  .frames-pagination li.disabled {
    pointer-events: none;
    cursor: not-allowed; }

.loader-background {
  background: #fafafa;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 100000; }
  .loader-background .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100000; }
