@import '../../../_theme2.scss';

.json-editor-container {
  width: min-content;
  margin-top: 2px;
  .tools {
    .import-json {
      position: relative;
      width: 100px;
      height: 35px;
      overflow: hidden;
      input {
        position: absolute;
        top: 0;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
      button {
        width: 100%;
        height: 100%;
        background: $white;
        color: $primary-green;
        border: 1px solid $primary-green;
        border-radius: 5px;
      }
    }
  }
}

.flex-common {
  display: flex;
  align-items: center;
}

.panel {
  .d-flex {
    @extend .flex-common;
    margin: 20px 0;
    label {
      font-weight: 900 !important;
      margin-right: 10px;
      margin-bottom: 0;
      font-weight: 400 !important;
      color: #000;
    }
    .form-control {
      width: 75% !important;
      display: inline-flex !important;
    }
    .dropdown {
      .btn {
        width: 110px;
        text-align: left;
        display: flex;
        justify-content: space-between;
      }
      .caret {
        margin-top: 7px;
      }
    }
    .flex {
      @extend .flex-common;
    }
  }
  .panel-footer {
    background-color: $white !important;
    min-height: 55px;
    .d-flex {
      display: flex;
      margin: 0 !important;
    }
    .no-border {
      border: none !important;
      background: transparent;
      color: $primary-orange;
      &:focus, &:hover {
        outline: none;
        box-shadow: none;
      }
    }
    .float-right {
      float: right;
    }
  }
}

.workout-header-sec {
  @extend .flex-common;
  .workout-header {
    @extend .flex-common;
    h4 {
      font-weight: 600;
      color: $workoutInactiveColor;
      cursor: pointer;
    }
    span {
      font-size: 18px;
      font-weight: 600;
      cursor: default !important;
      color: $workoutTextColor;
    }
  }
}

.workout-btn-actions {
  .btn {
    &:focus, &:hover {
      outline: none;
      box-shadow: none;
    }
  }
  .cancel-btn {
    background: transparent;
    color: #8a8a8a;
  }
}

.error-logs {
  height: 200px;
  overflow-y: scroll;
  p {
    padding: 0 10px;
    color: $secondaryTextColor;
  }
}
