.my-acc-hdr {
  margin: 0; }

.my-acc-body {
  border: 1px solid #ddd;
  border-top: none; }
  .my-acc-body .form-label-group {
    margin-bottom: 25px; }
    .my-acc-body .form-label-group label {
      color: #000;
      font-size: 14px; }
  .my-acc-body .password-sec {
    border: 1px solid #eaeaea;
    background-color: #fafafa;
    padding: 15px 20px;
    border-radius: 8px;
    margin-bottom: 5px; }
    .my-acc-body .password-sec h4 {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin: 10px 0 20px; }
    .my-acc-body .password-sec .p-l-0 {
      padding-left: 0; }
    .my-acc-body .password-sec .p-r-0 {
      padding-right: 0; }
  .my-acc-body .change-pro-pic input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    left: 0; }
  .my-acc-body .change-pro-pic a {
    cursor: pointer; }

@media (max-width: 767px) {
  .my-acc-body .password-sec .p-l-0 {
    padding-left: 15px; }
  .my-acc-body .password-sec .p-r-0 {
    padding-right: 15px; }
  .my-acc-body .password-sec .form-label-group {
    padding: 0; } }

.change-pic-sec {
  text-align: center;
  margin: 5px 0 0; }
  .change-pic-sec .profile-pic {
    width: 90px;
    height: 90px;
    border-radius: 20px;
    margin-bottom: 20px; }
  .change-pic-sec a {
    display: block;
    margin-bottom: 10px; }

.prof-desc-sec .prof-cnt {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px; }
  .prof-desc-sec .prof-cnt h5 {
    float: left;
    margin: 0;
    padding-right: 10px;
    color: #7f7f7f; }
  .prof-desc-sec .prof-cnt p {
    float: left;
    margin: 0;
    color: #000; }
