/* inject:scss */
/* endinject */
.d-flex {
  display: flex;
  align-items: center; }

.user-sec {
  display: flex;
  margin-right: 40px; }
  .user-sec .logo {
    height: 40px;
    width: 40px !important;
    border: 1px solid #fff;
    border-radius: 20px;
    margin-right: 10px;
    margin-top: -3px; }
  .user-sec .user-cnt {
    flex-flow: column;
    align-items: left;
    cursor: default;
    outline: none; }
    .user-sec .user-cnt h4 {
      color: #fff;
      font-size: 16px;
      margin: 0 0 3px; }
    .user-sec .user-cnt h5 {
      margin-top: 0px !important;
      color: #0091ff;
      font-size: 14px;
      margin: 0; }

.logo-sec {
  display: flex;
  margin: 10px 0;
  border-right: 2px solid #000; }
  .logo-sec p {
    margin: 4px 20px 4px 10px;
    font-weight: 800;
    font-size: 20px;
    color: white; }
  .logo-sec .app-logo {
    border-radius: 10px; }

.app-sec {
  display: flex;
  margin: 0 20px; }

.navbar {
  border-radius: 0;
  background-color: #2D3033;
  min-height: 60px !important;
  z-index: 99; }
  .navbar span[role=button] {
    padding: 15px;
    display: inline-block;
    line-height: 20px; }
  .navbar .navbar-brand {
    color: #fff; }
  .navbar .nav-link.active, .navbar .nav-link:hover {
    color: #fff; }
  .navbar .glyphicon {
    color: #fff; }
  .navbar .dropdown-toggle {
    text-transform: capitalize; }
    .navbar .dropdown-toggle:active, .navbar .dropdown-toggle:hover {
      color: #fff; }

.themeName {
  text-transform: capitalize; }

.themeIcon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px; }

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: #fff; }
